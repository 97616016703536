import React, { ReactNode, useCallback } from "react"
import { Label, P } from "@jobintrans/base-ui.components.atoms.typography"
import { Trans, useTranslation } from "react-i18next"
import { Box, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import styled from "styled-components"
import Button from "@jobintrans/base-ui.components.atoms.button"
import { LocalStorageService } from "@jobintrans/base-ui.services.api-service"
import { localeToIcon } from "utils/flags.utils"
import { locales } from "utils/lang.utils"

export const RegisterHelperText = () => {
  const { t } = useTranslation("register")

  return (
    <P textAlign="center" small margin="0">
      <Trans
        i18nKey={t("helper-text")}
        components={{
          href: <a href="mailto:pomoc@jobintrans.eu" />,
        }}
      />
    </P>
  )
}

export const LoginHelperText = () => {
  const { t } = useTranslation("login")

  return (
    <P textAlign="center" small margin="0">
      {t("go-back-to-platform")} <a href={process.env.REACT_APP_DOMAIN}>jobintrans.eu</a>.
    </P>
  )
}

export const AddOfferHelperText = () => {
  const { t } = useTranslation("add-offer")

  return (
    <P textAlign="center" small margin="0">
      <Trans
        i18nKey={t("helper-text")}
        components={{
          href: <a href="mailto:pomoc@jobintrans.eu" />,
        }}
      />
    </P>
  )
}

export const ChangeLanguageComponent = ({ children }: { children?: ReactNode }) => {
  const { t, i18n } = useTranslation("login")

  const setLanguage = useCallback((lang: string) => {
    LocalStorageService.setObject("lang", lang)
    i18n.changeLanguage(lang)
  }, [])

  return (
    <Row position="relative" width="100%" alignItems="center" style={{ gridGap: "10px" }}>
      <Box flex={1}>{children}</Box>
      <LanguageButton>
        <Label type="secondary">
          {localeToIcon[i18n.language]} {i18n.language.toUpperCase()}
        </Label>
        <LanguageSelect className="select" style={{ display: "none" }}>
          <Box>
            {locales.map(lng => (
              <LanguageSelectButton key={lng} type="third" onClick={() => setLanguage(lng)}>
                {localeToIcon[lng]} {lng.toUpperCase()}
              </LanguageSelectButton>
            ))}
          </Box>
        </LanguageSelect>
      </LanguageButton>
    </Row>
  )
}

const LanguageButton = styled(Box)`
  position: relative;
  padding: 0;
  z-index: 1;

  &:hover {
    .select {
      display: block !important;
    }
  }
`

const LanguageSelectButton = styled(Button)`
  padding: 10px 0;
  width: 50px;
`

const LanguageSelect = styled(Box)`
  position: absolute !important;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  padding-bottom: 10px;
  width: max-content;
  display: none;

  > div {
    position: relative;
    background: white;
    border-radius: ${({ theme }) => theme.variable.borderRadius};
    box-shadow: ${({ theme }) => theme.variable.boxShadow};

    &:before {
      display: block;
      content: "";
      position: absolute;
      height: 15px;
      width: 15px;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      background: white;
      border-radius: 2px;
    }
  }
`
