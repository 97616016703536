import React, { useCallback } from "react"
import Modal from "@jobintrans/base-ui.components.molecules.modal"
import Input from "@jobintrans/base-ui.components.atoms.input"
import Button from "@jobintrans/base-ui.components.atoms.button"
import { LocalStorageService, PublicService } from "@jobintrans/base-ui.services.api-service"
import { useForm } from "react-hook-form"
import { EMAIL_REGEX } from "@jobintrans/base-ui.utils.regex"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Alert from "@jobintrans/base-ui.components.molecules.alert"
import TextCheckbox from "@jobintrans/base-ui.components.atoms.text-checkbox"
import { useTranslation, Trans } from "react-i18next"
import { Box } from "@jobintrans/base-ui.components.atoms._atoms"
import { SentryService } from "services/SentryService"
import { errorRegister, initRegister, successRegister } from "store/auth"
import { RootState } from "store/store"
import { ChangeLanguageComponent, RegisterHelperText } from "components/shared/ModalHelpers"
import { AnalyticsService } from "services/AnalyticsService"

const Register = () => {
  const { t, i18n } = useTranslation(["register", "common", "form"])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
      checkboxAcceptTerms: false,
      checkboxAcceptNewsletter: false,
    },
  })

  const { isRegisteringIn, isRegisterError } = useSelector((state: RootState) => state.auth)

  const checkboxAcceptTerms = watch("checkboxAcceptTerms")
  const checkboxAcceptNewsletter = watch("checkboxAcceptNewsletter")

  const onSubmit = useCallback(
    async (el: any) => {
      dispatch(initRegister())
      try {
        const user = await PublicService.register(el.username, el.password, {
          newsletter: el.checkboxAcceptNewsletter,
          language: i18n.language,
        })
        AnalyticsService.identify(user.id, {
          email: user.username,
        })

        await LocalStorageService.setObject("token", user.token)
        dispatch(successRegister(user))
      } catch (e) {
        SentryService.error("[ERROR PublicService.register]:", e)
        dispatch(errorRegister())
        return
      }
    },
    [i18n.language],
  )

  const handleLogin = useCallback(() => {
    navigate("/login")
  }, [])

  return (
    <Modal
      show={true}
      icon="shield"
      title={t("title", { ns: "register" })}
      text={t("text", { ns: "register" })}
      maxWidth="400px"
      showBackground={false}
      helperElement={
        <ChangeLanguageComponent>
          <RegisterHelperText />
        </ChangeLanguageComponent>
      }
    >
      <>
        {isRegisterError && (
          <Alert type="error" small marginBottom="S">
            <Trans
              i18nKey={t("error", { ns: "register" })}
              components={{
                href: <a target="_blank" rel="noreferrer" href="mailto:pomoc@jobintrans.eu" />,
              }}
            />
          </Alert>
        )}
        <Input
          name="username"
          label={`${t("email", { ns: "form" })}*`}
          error={errors.username}
          rhf={register("username", {
            required: t("required", { ns: "form" }),
            pattern: {
              value: EMAIL_REGEX,
              message: t("wrong-email", { ns: "form" }),
            },
          })}
          marginBottom="S"
        />
        <Input
          name="password"
          label={`${t("password", { ns: "form" })}*`}
          type="password"
          error={errors.password}
          rhf={register("password", {
            required: t("required", { ns: "form" }),
            pattern: {
              value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
              message: t("wrong-password", { ns: "form" }),
            },
          })}
          marginBottom="S"
        />
        <TextCheckbox
          name="checkboxAll"
          value={checkboxAcceptTerms && checkboxAcceptNewsletter}
          onClick={() => {
            setValue("checkboxAcceptTerms", true)
            setValue("checkboxAcceptNewsletter", true)
          }}
          marginBottom="XS"
        >
          {t("legal.accept-all", { ns: "form" })}
        </TextCheckbox>
        <Box marginLeft="S" marginBottom="M">
          <TextCheckbox
            name="checkboxAcceptTerms"
            value={checkboxAcceptTerms}
            error={errors.checkboxAcceptTerms}
            rhf={register("checkboxAcceptTerms", {
              required: t("required", { ns: "form" }),
            })}
            marginBottom="XS"
          >
            *
            <Trans
              i18nKey={t("legal.accept-terms", { ns: "form" })}
              components={{
                terms: <a target="_blank" rel="noreferrer" href="https://jobintrans.eu/pl/terms" />,
                privacy: (
                  <a target="_blank" rel="noreferrer" href="https://jobintrans.eu/pl/privacy" />
                ),
              }}
            />
          </TextCheckbox>
          <TextCheckbox
            name="checkboxAcceptNewsletter"
            value={checkboxAcceptNewsletter}
            error={errors.checkboxAcceptNewsletter}
            rhf={register("checkboxAcceptNewsletter")}
          >
            <Trans
              i18nKey={t("legal.accept-newsletter", { ns: "form" })}
              components={{
                terms: (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://jobintrans.eu/pl/terms-newsletter"
                  />
                ),
                privacy: (
                  <a target="_blank" rel="noreferrer" href="https://jobintrans.eu/pl/privacy" />
                ),
              }}
            />
          </TextCheckbox>
        </Box>
        <Button
          width="100%"
          marginBottom="XS"
          loading={isRegisteringIn}
          onClick={handleSubmit(onSubmit)}
        >
          {t("register", { ns: "common" })}
        </Button>
        <Button type="third" width="100%" onClick={handleLogin}>
          {t("login", { ns: "common" })}
        </Button>
      </>
    </Modal>
  )
}

export default Register
