import React, { ReactNode, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { AnalyticsService } from "services/AnalyticsService"
import { RootState } from "store/store"

const Analytics = ({ children }: { children: ReactNode }) => {
  const { isVerifying, isAuthenticated, user } = useSelector((state: RootState) => state.auth)
  const { pathname } = useLocation()

  useEffect(() => {
    if (isAuthenticated && user?.role === "ROLE_PROFILE") {
      AnalyticsService.page(pathname)
    }
  }, [pathname, isAuthenticated])

  return <>{children}</>
}

export default Analytics
