import React, { useState } from "react"
import { AdminOfferTransactionType } from "@jobintrans/base-ui.types.api"
import { AdminTransactionsService } from "@jobintrans/base-ui.services.api-service"
import { Box, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { LabelH2 } from "@jobintrans/base-ui.components.atoms.typography"
import { formatPrice } from "@jobintrans/base-ui.utils.number"
import Tag from "@jobintrans/base-ui.components.atoms.tag"
import Button, { IconButton } from "@jobintrans/base-ui.components.atoms.button"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { formatDate } from "utils/date"
import Tags from "components/shared/Tags"
import Status from "components/atoms/Status/Status"
import { SentryService } from "services/SentryService"

const TransactionBlock = ({ transaction }: { transaction: AdminOfferTransactionType }) => {
  const [status, setStatus] = useState(transaction.status)

  const { t } = useTranslation(["add-offer"])
  const { t: tData } = useTranslation(["data"])

  const [loading, setLoading] = useState(false)

  const handleTransactionStatus = async (newstatus: string) => {
    try {
      setLoading(true)
      await AdminTransactionsService.changeStatus(transaction.id, newstatus)
      setStatus(newstatus)
    } catch (e) {
      SentryService.error("[ERROR AdminTransactionsService.changeStatus]:", e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <TransactionWrapper>
      <Row marginBottom="XS">
        <Status label={status} marginRight="XS" />
        <Row justifyContent="space-between" flex={1}>
          <LabelH2 margin="0">
            {tData(`products.${transaction.product.type}_${transaction.product.slug}`, {
              quantity: transaction.quantity,
            })}
          </LabelH2>
          <LabelH2 margin="0">
            {formatPrice(transaction.value)} {transaction.currency}
          </LabelH2>
        </Row>
      </Row>
      <Tags>
        {transaction.discount && (
          <Tag
            type="secondary"
            showBorder={false}
            background="background"
            icon="tag"
            iconColor="primary"
            label={transaction.discount}
          />
        )}
        <Tag
          type="secondary"
          showBorder={false}
          background="background"
          icon="sallary"
          iconColor="primary"
          label={tData(`paymentMethodTypes.${transaction.paymentMethodType}`)}
        />
        {transaction.dtCreated && (
          <Tag
            type="secondary"
            showBorder={false}
            background="background"
            icon="clock-check"
            iconColor="primary"
            label={formatDate(transaction.dtCreated)}
          />
        )}
      </Tags>
      {status === "unpaid" && (
        <Row marginTop="S">
          <Button
            width="100%"
            loading={loading}
            background="green"
            hoverBackground="greenDarker"
            icon="check-circle"
            onClick={() => handleTransactionStatus("paid")}
          >
            Oznacz jako opłacone
          </Button>
          <IconButton
            marginLeft="XS"
            icon="close"
            ariaLabel="Reject transaction"
            size="45px"
            iconSize="M"
            borderRadius="borderRadius"
            onClick={() => handleTransactionStatus("rejected")}
            background="red"
            color="white"
          />
        </Row>
      )}
    </TransactionWrapper>
  )
}

export default TransactionBlock

const TransactionWrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.XS};
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
`
