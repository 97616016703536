import { useSelector } from "react-redux"
import { RootState } from "store/store"

export default function useProfile() {
  const { profile } = useSelector((state: RootState) => state.profile)
  return profile
}

export function useProfileFeature(name: string) {
  const { profile } = useSelector((state: RootState) => state.profile)
  return profile?.featureFlags?.includes(name) ?? false
}
