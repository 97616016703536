import { AnalyticsBrowser } from "@customerio/cdp-analytics-browser"

export const analytics = AnalyticsBrowser.load({
  cdnURL: "https://cdp-eu.customer.io",
  writeKey: `${process.env.REACT_APP_CUSTOMER_IO_KEY}`,
})

const identify = (id?: string | null, properties?: object) => {
  analytics?.identify(id, properties)
}

const page = (name: string, properties?: object) => {
  analytics?.page(name, properties)
}

const track = (name: string, properties?: object) => {
  analytics?.track(name, properties)
}

export const AnalyticsService = {
  identify,
  page,
  track,
}
