import React from "react"
import { Label } from "@jobintrans/base-ui.components.atoms.typography"

const PlatformLink = ({ status, to }: { status: boolean; to: string }) => {
  if (status) {
    return (
      <a href={`${process.env.REACT_APP_DOMAIN}${to}`} target="_blank" rel="noreferrer">
        <Label type="third" color="primary">
          jobintrans.eu{to}
        </Label>
      </a>
    )
  }
  return (
    <Label type="third" color="red">
      jobintrans.eu{to}
    </Label>
  )
}

export default PlatformLink
