import React, { useCallback, useEffect, useState } from "react"
import Modal from "@jobintrans/base-ui.components.molecules.modal"
import Input from "@jobintrans/base-ui.components.atoms.input"
import Button from "@jobintrans/base-ui.components.atoms.button"
import { LocalStorageService, ProfileService } from "@jobintrans/base-ui.services.api-service"
import { useForm } from "react-hook-form"
import { EMAIL_REGEX, WEBSITE_REGEX } from "@jobintrans/base-ui.utils.regex"
import { useDispatch } from "react-redux"
import RadioSlider from "@jobintrans/base-ui.components.atoms.radio-slider"
import Select from "@jobintrans/base-ui.components.atoms.select"
import {
  COUNTRIES_ARRAY,
  PROFILEINDUSTRIE_ARRAY,
  PROFILESIZE_ARRAY,
} from "@jobintrans/base-ui.data"
import { useNavigate } from "react-router-dom"
import Alert from "@jobintrans/base-ui.components.molecules.alert"
import { removeSpacesFromPhoneNumber } from "@jobintrans/base-ui.utils.string"
import styled from "styled-components"
import { Box } from "@jobintrans/base-ui.components.atoms._atoms"
import { H3, P } from "@jobintrans/base-ui.components.atoms.typography"
import { Trans, useTranslation } from "react-i18next"
import { SentryService } from "services/SentryService"
import { logout, updateUser } from "store/auth"
import { setProfile, setProfileIds } from "store/profile"
import { RegisterHelperText, ChangeLanguageComponent } from "components/shared/ModalHelpers"
import { useSearchCompanyByNumber } from "hooks/useSearchCompanyByNumber"
import { AnalyticsService } from "services/AnalyticsService"

const RegisterProfilePersonal = ({ register, unregister, watch, errors }: any) => {
  const { t } = useTranslation(["form"])

  const countryCode = watch("address.countryCode")

  useEffect(() => {
    return () => {
      unregister("name")
      unregister("address")
      unregister("phoneNumber")
    }
  }, [])

  return (
    <>
      <Input
        name="name"
        label={`${t("fullName")}*`}
        error={errors.name}
        rhf={register("name", {
          required: t("required"),
        })}
        marginBottom="S"
      />
      <Select
        name="address.countryCode"
        label={`${t("country")}*`}
        options={COUNTRIES_ARRAY.map(el => [t(`localization.${el[1]}`, { ns: "data" }), el[1]])}
        error={(errors.address as any)?.countryCode}
        value={watch("address.countryCode")}
        rhf={register("address.countryCode", {
          required: t("required"),
        })}
        marginBottom="S"
      />
      <Input
        name="address.address"
        label={`${t("address")}*`}
        error={(errors.address as any)?.address}
        rhf={register("address.address", {
          required: t("required"),
        })}
        marginBottom="S"
        disabled={!countryCode}
      />
      <StyledGrid marginBottom="S">
        <Input
          name="address.city"
          label={`${t("city")}*`}
          error={(errors.address as any)?.city}
          rhf={register("address.city", {
            required: t("required"),
          })}
          disabled={!countryCode}
        />
        <Input
          name="address.postCode"
          label={`${t("postCode")}*`}
          elementType={countryCode === "PL" ? "pattern" : "text"}
          format="##-###"
          formattedValue={true}
          error={(errors.address as any)?.postCode}
          value={watch("address.postCode")}
          rhf={register("address.postCode", {
            required: t("required"),
          })}
          disabled={!countryCode}
        />
      </StyledGrid>
      <Input
        name="phoneNumber"
        label={`${t("phone")}*`}
        error={errors?.phoneNumber}
        value={watch("phoneNumber")}
        rhf={register("phoneNumber", {
          required: t("required"),
        })}
        marginBottom="S"
      />
      <Input
        name="email"
        label={`${t("email", { ns: "form" })}*`}
        error={errors.email}
        rhf={register("email", {
          required: t("required"),
          pattern: {
            value: EMAIL_REGEX,
            message: t("wrong-email"),
          },
        })}
      />
    </>
  )
}

const RegisterProfileCompany = ({ setValue, register, unregister, watch, errors }: any) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(["form", "register"])

  const [file, setFile] = useState(false)

  const name = watch("company.name")
  const address = watch("company.address")
  const city = watch("company.city")
  const postCode = watch("company.postCode")
  const countryCode = watch("company.countryCode")
  const number = watch("company.number")

  const [isLoadingCompany, isValidCompany] = useSearchCompanyByNumber(countryCode, number, e => {
    setValue("company.name", e.name || name)
    setValue("company.address", e.address || address)
    setValue("company.city", e.city || city)
    setValue("company.postCode", e.postCode || postCode)
  })

  useEffect(() => {
    return () => {
      unregister("company")
      unregister("phoneNumber")
    }
  }, [])

  return (
    <>
      <StyledGrid marginBottom="S">
        <Select
          name="company.countryCode"
          label={`${t("country", { ns: "form" })}*`}
          options={COUNTRIES_ARRAY.map(el => [t(`localization.${el[1]}`, { ns: "data" }), el[1]])}
          error={(errors.company as any)?.countryCode}
          value={countryCode}
          rhf={register("company.countryCode", {
            required: t("required", { ns: "form" }),
          })}
        />
        <Input
          name="company.number"
          label={`${t("company.number", { ns: "form" })}*`}
          elementType={countryCode === "PL" ? "pattern" : "text"}
          format="##########"
          error={(errors.company as any)?.number}
          value={number}
          startAdornment={countryCode?.toUpperCase()}
          rhf={register("company.number", {
            required: t("required", { ns: "form" }),
          })}
          success={isValidCompany}
          disabled={!countryCode}
          loading={isLoadingCompany}
        />
      </StyledGrid>
      <Input
        name="company.name"
        label={`${t("company.name", { ns: "form" })}*`}
        error={(errors.company as any)?.name}
        rhf={register("company.name", {
          required: t("required", { ns: "form" }),
        })}
        disabled={!countryCode}
        marginBottom="S"
      />
      <Input
        name="company.address"
        label={`${t("address", { ns: "form" })}*`}
        error={(errors.company as any)?.address}
        rhf={register("company.address", {
          required: t("required", { ns: "form" }),
        })}
        disabled={!countryCode}
        marginBottom="S"
      />
      <StyledGrid marginBottom="M">
        <Input
          name="company.city"
          label={`${t("city", { ns: "form" })}*`}
          error={(errors.company as any)?.city}
          rhf={register("company.city", {
            required: t("required", { ns: "form" }),
          })}
          disabled={!countryCode}
        />
        <Input
          name="company.postCode"
          label={`${t("postCode", { ns: "form" })}*`}
          elementType={countryCode === "PL" ? "pattern" : "text"}
          format="##-###"
          formattedValue={true}
          error={(errors.company as any)?.postCode}
          value={watch("company.postCode")}
          rhf={register("company.postCode", {
            required: t("required", { ns: "form" }),
          })}
          disabled={!countryCode}
        />
      </StyledGrid>
      <H3 marginBottom="XXS">{t("profile.additional-informations.title", { ns: "register" })}</H3>
      <P small marginBottom="S">
        {t("profile.additional-informations.text", { ns: "register" })}
      </P>
      <Input
        name="name"
        label={`${t("profile.name", { ns: "form" })}*`}
        error={errors.name}
        rhf={register("name", {
          required: t("required", { ns: "form" }),
        })}
        marginBottom="S"
      />
      <StyledGrid marginBottom="S">
        <Input
          name="phoneNumber"
          label={`${t("phone")}*`}
          error={errors?.phoneNumber}
          value={watch("phoneNumber")}
          rhf={register("phoneNumber", {
            required: t("required", { ns: "form" }),
          })}
        />
        <Input
          name="email"
          label={`${t("email", { ns: "form" })}*`}
          error={errors.email}
          rhf={register("email", {
            required: t("required", { ns: "form" }),
            pattern: {
              value: EMAIL_REGEX,
              message: t("wrong-email", { ns: "form" }),
            },
          })}
        />
      </StyledGrid>
      <StyledGrid marginBottom="S">
        <Select
          name="companyIndustrie"
          label={`${t("profile.industrie", { ns: "form" })}*`}
          options={PROFILEINDUSTRIE_ARRAY.map(el => [t(el[1], { ns: "data" }), el[1]])}
          error={errors.companyIndustrie}
          value={watch("companyIndustrie")}
          rhf={register("companyIndustrie", {
            required: t("required", { ns: "form" }),
          })}
        />
        <Select
          name="companySize"
          label={t("profile.size", { ns: "form" })}
          options={PROFILESIZE_ARRAY}
          error={errors.companySize}
          value={watch("companySize")}
          rhf={register("companySize")}
        />
      </StyledGrid>
      <Input
        name="website"
        label={t("profile.website", { ns: "form" })}
        error={errors?.website}
        value={watch("website")}
        rhf={register("website", {
          pattern: {
            value: WEBSITE_REGEX,
            message: t("wrong-website", { ns: "form" }),
          },
        })}
        startAdornment="https://"
        marginBottom="S"
      />
      {/*<Box marginBottom="S">*/}
      {/*  <Label type="secondary" marginBottom="XS">*/}
      {/*    Logo firmy*/}
      {/*  </Label>*/}
      {/*  {file ? (*/}
      {/*    <StyledLogoWrapper>*/}
      {/*      <img src="https://www.kuznia-trans.pl/static/svg/logo.svg" />*/}
      {/*      <Absolute right="10px" top="10px">*/}
      {/*        <IconButton*/}
      {/*          icon="delete"*/}
      {/*          background="backgroundDarker"*/}
      {/*          onClick={() => setFile(false)}*/}
      {/*        />*/}
      {/*      </Absolute>*/}
      {/*    </StyledLogoWrapper>*/}
      {/*  ) : (*/}
      {/*    <ImageUploader onChange={setFile} />*/}
      {/*  )}*/}
      {/*</Box>*/}
    </>
  )
}

const RegisterProfile = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation(["common", "form", "register"])
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    unregister,
    formState: { errors, isValid },
  } = useForm()

  const [type, setType] = useState("company")
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleLogOut = useCallback(() => {
    LocalStorageService.removeObject("token")
    dispatch(logout())
  }, [])

  const onSubmit = useCallback(
    async (el: any) => {
      setError(false)
      setLoading(true)
      try {
        let obj = {}
        if (type === "company") {
          obj = {
            type: "company",
            name: el.name,
            phone: removeSpacesFromPhoneNumber(el.phoneNumber),
            email: el.email,
            website: el.website,
            companyIndustrie: el.companyIndustrie,
            companySize: el.companySize,
            company: {
              ...el.company,
            },
            address: {
              ...el.company,
            },
            lang: i18n.language,
          }
        } else {
          obj = {
            type: "personal",
            name: el.name,
            phone: removeSpacesFromPhoneNumber(el.phoneNumber),
            email: el.email,
            address: el.address,
          }
        }

        // @ts-ignore
        const profile = await ProfileService.registerProfile(obj)
        await LocalStorageService.setObject("profileId", `${profile.id}`)
        AnalyticsService.track("panel_register_profile_success")
        dispatch(setProfile(profile))
        dispatch(setProfileIds([profile.id]))
        dispatch(
          updateUser({
            status: "pending",
          }),
        )
      } catch (e) {
        SentryService.error("[ERROR ProfileService.registerProfile]:", e)
        setError(true)
        return
      } finally {
        setLoading(false)
      }
    },
    [type, i18n.language],
  )

  useEffect(() => {
    AnalyticsService.track("panel_register_profile_open")
  }, [])

  return (
    <Modal
      show={true}
      icon="shield"
      title={t("profile.title", { ns: "register" })}
      text={t("profile.text", { ns: "register" })}
      maxWidth="650px"
      showBackground={false}
      mobileFullScreen={true}
      helperElement={
        <ChangeLanguageComponent>
          <RegisterHelperText />
        </ChangeLanguageComponent>
      }
      helperButton={{
        text: t("logout", { ns: "common" }),
        onClick: handleLogOut,
      }}
      bottomElement={
        <Button width="100%" loading={loading} onClick={handleSubmit(onSubmit)}>
          {t("next", { ns: "common" })}
        </Button>
      }
    >
      <>
        {error && (
          <Alert type="error" small marginBottom="S">
            <Trans
              i18nKey={t("error", { ns: "register" })}
              components={{
                href: <a target="_blank" rel="noreferrer" href="mailto:pomoc@jobintrans.eu" />,
              }}
            />
          </Alert>
        )}
        <RadioSlider
          name="slider"
          options={[
            [t("profile.profile-types.company", { ns: "register" }), "company"],
            [t("profile.profile-types.personal", { ns: "register" }), "personal"],
          ]}
          value={type}
          onChange={({ value }) => setType(value)}
          background="backgroundDarker"
          marginBottom="S"
        />
        {type === "company" ? (
          <RegisterProfileCompany
            unregister={unregister}
            setValue={setValue}
            watch={watch}
            register={register}
            errors={errors}
          />
        ) : (
          <RegisterProfilePersonal
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            errors={errors}
          />
        )}
      </>
    </Modal>
  )
}

export default RegisterProfile

const StyledLogoWrapper = styled(Box)`
  height: 150px;
  width: 100%;
  background: ${({ theme }) => theme.color.background};
  padding: ${({ theme }) => theme.space.S};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  margin-bottom: ${({ theme }) => theme.space.S};
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const StyledGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.S};
`
