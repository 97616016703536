import { configureStore } from "@reduxjs/toolkit"
import {
  LocalStorageService,
  ProfileService,
  PublicService,
  UserService,
} from "@jobintrans/base-ui.services.api-service"
import auth, { errorVerify, initVerify, successVerify } from "./auth"
import profile, { setProfile, setProfileIds } from "./profile"
import { getQuery } from "utils/queryHelper"
import { AnalyticsService } from "services/AnalyticsService"

export const store = configureStore({
  reducer: {
    auth: auth,
    profile: profile,
  },
})

const { dispatch } = store

const clear = async () => {
  LocalStorageService.removeObject("token")
  LocalStorageService.removeObject("profileId")
  dispatch(errorVerify())
}

const verifyUser = async () => {
  const query = getQuery()
  if (query.get("token")) {
    await LocalStorageService.setObject("token", `${query.get("token")}`)
  }

  dispatch(initVerify())
  let user = null
  try {
    user = await PublicService.verify()
  } catch (e) {
    console.log("[ERROR PublicService.verify]:", e)
    clear()
    return
  }

  let profileIds = null
  try {
    profileIds = await UserService.getProfiles(user.id)
    dispatch(setProfileIds(profileIds))
  } catch (e) {
    console.log("[ERROR UserService.getProfiles]:", e)
    clear()
    return
  }

  if (profileIds && profileIds[0]) {
    LocalStorageService.setObject("profileId", profileIds[0])
    try {
      const profile = await ProfileService.getProfile(profileIds[0])
      dispatch(setProfile(profile))
    } catch (e) {
      console.log("[ERROR ProfileService.getProfile[:", e)
      clear()
      return
    }
  }

  dispatch(successVerify(user))
  AnalyticsService.identify(user.id, {
    email: user.username,
  })
}

verifyUser()

export type RootState = ReturnType<typeof store.getState>
