import React from "react"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { AdminOfferType } from "@jobintrans/base-ui.types.api"
import { AdminOffersService } from "@jobintrans/base-ui.services.api-service"
import { Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { IconButton } from "@jobintrans/base-ui.components.atoms.button"
import { H2 } from "@jobintrans/base-ui.components.atoms.typography"
import { useCustomQuery } from "hooks/useCustomQuery"
import { RootState } from "store/store"
import OfferDetails from "views/Admin/Offers/OfferDetails"

const OfferInfo = () => {
  const { t } = useTranslation(["panel", "common"])
  const navigate = useNavigate()
  const { offerId = "" } = useParams()

  const { profile } = useSelector((state: RootState) => state.profile)

  const { isLoading, data } = useCustomQuery<AdminOfferType>({ key: ["offer", offerId] }, () =>
    AdminOffersService.getOffer(offerId),
  )

  return (
    <>
      <Row alignItems="center">
        <IconButton
          background="background"
          size="45px"
          iconSize="L"
          borderRadius="borderRadius"
          onClick={() => navigate(-1)}
          icon="arrow-left"
          marginRight="S"
          ariaLabel="Go back"
        />
        <H2 margin="0">{data?.name}</H2>
      </Row>
      <OfferDetails loading={isLoading} data={data} />
      <Outlet />
    </>
  )
}

export default OfferInfo
