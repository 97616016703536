import React, { useState } from "react"
import { ApplicationType } from "@jobintrans/base-ui.types.api"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { ProfileOfferService } from "@jobintrans/base-ui.services.api-service"
import { IconButton } from "@jobintrans/base-ui.components.atoms.button"
import { Box } from "@jobintrans/base-ui.components.atoms._atoms"
import styled from "styled-components"
import { SentryService } from "services/SentryService"
import Link from "components/atoms/Link/Link"

const OfferApplicationRowActions = ({
  offerId,
  application,
}: {
  offerId: string
  application: ApplicationType
}) => {
  const navigate = useNavigate()

  const { t } = useTranslation(["panel", "data"])
  const [loading, setLoading] = useState(false)
  const [wasRead, setWasRead] = useState(application.wasRead)

  const downloadFile = async () => {
    try {
      setLoading(true)
      const file = await ProfileOfferService.getOfferApplicationFile(`${offerId}`, application.id)
      const pdfBlob = new Blob([file], { type: "application/pdf" })
      const url = window.URL.createObjectURL(pdfBlob)
      const tempLink = document.createElement("a")
      tempLink.href = url
      tempLink.setAttribute(
        "download",
        `${application.firstName.toLowerCase().replaceAll(" ", "-")}_${application.lastName
          .toLowerCase()
          .replaceAll(" ", "-")}.${application.file?.type}`,
      )

      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)
      window.URL.revokeObjectURL(url)
    } catch (e) {
      SentryService.error("[ERROR ProfileOfferService.getOfferApplicationFile]:", e)
    } finally {
      setLoading(false)
    }
  }

  const handleWasRead = () => {
    try {
      setWasRead(true)
      ProfileOfferService.readOfferApplications(offerId, application.id)
    } catch (e) {
      SentryService.error("[ERROR ProfileOfferService.readOfferApplications]:", e)
    }
  }

  if (!application.isActive) {
    return (
      <ApplicationButtons>
        <Link type="secondary" icon="unlock" to={`/extend-offer/${offerId}`}>
          {t("offers.offer.candidates.unlock")}
        </Link>
      </ApplicationButtons>
    )
  }

  return (
    <ApplicationButtons>
      {application.file && (
        <IconButton
          iconSize="M"
          size="45px"
          borderRadius="borderRadius"
          icon="cv"
          hoverColor="primary"
          onClick={downloadFile}
          ariaLabel="Application file"
        />
      )}
      {application.hasSurveyAnswer && (
        <IconButton
          iconSize="M"
          size="45px"
          borderRadius="borderRadius"
          icon="check-square"
          hoverColor="primary"
          onClick={() => navigate(`survey/${application.id}`)}
          ariaLabel="Survey"
        />
      )}
      {application.hasAccount && (
        <IconButton
          iconSize="M"
          size="45px"
          borderRadius="borderRadius"
          icon="user"
          hoverColor="primary"
          onClick={() => navigate(`account/${application.id}`)}
          ariaLabel="Account"
        />
      )}
      <Box position="relative">
        <IconButton
          iconSize="M"
          size="45px"
          borderRadius="borderRadius"
          icon="check-circle"
          hoverColor="primary"
          onClick={handleWasRead}
          disabled={wasRead}
          ariaLabel="Read application"
        />
        {!wasRead && <ReadDot />}
      </Box>
    </ApplicationButtons>
  )
}

export default OfferApplicationRowActions

const ReadDot = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  transform: translate(50%, -50%);
  border-radius: 50%;
  background: ${({ theme }) => theme.color.primary};

  &:before {
    position: absolute;
    left: -5px;
    top: -5px;
    content: "";
    display: block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: ${({ theme }) => theme.color.primaryBackground};
    animation-name: scaleAnim;
    animation-duration: 1s;
    animation-iteration-count: infinite;

    @keyframes scaleAnim {
      0% {
        transform: scale(0.5);
      }
      50% {
        transform: scale(1);
      }
      100% {
        transform: scale(0.5);
      }
    }
  }

  &:after {
    position: absolute;
    left: 0px;
    top: 0px;
    content: "";
    display: block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background: ${({ theme }) => theme.color.primary};
  }
`

const ApplicationButtons = styled(Box)`
  display: flex;
  justify-content: flex-end;
  grid-gap: ${({ theme }) => theme.space.XS};
`
