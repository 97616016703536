import React, { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { AdminTransactionType } from "@jobintrans/base-ui.types.api"
import { AdminTransactionsService } from "@jobintrans/base-ui.services.api-service"
import styled from "styled-components"
import { Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { Label } from "@jobintrans/base-ui.components.atoms.typography"
import Switch from "@jobintrans/base-ui.components.atoms.switch"
import { SentryService } from "services/SentryService"
import TransactionList from "views/Admin/Transactions/TransactionList"
import PanelLayoutContent from "components/layout/PanelLayoutContent"

const Transactions = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [status, setStatus] = useState("all")
  const [data, setData] = useState<AdminTransactionType[]>([])

  const fetchData = async (newPage: number, newstatus: string) => {
    try {
      setLoading(true)
      const { content, totalPages, totalElements } = await AdminTransactionsService.getTransactions(
        newstatus,
        newPage,
      )
      setData(content)
      setTotalPages(totalPages)
    } catch (e) {
      SentryService.error("[ERROR AdminTransactionsService.getTransactions]:", e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData(page, status)
  }, [page])

  return (
    <>
      <PanelLayoutContent title="Lista transakcji" text="Lista wszystkich transakcji na platformie">
        <Row marginBottom="S" justifyContent="flex-end" alignItems="flex-end">
          <SwitchWrapper
            justifyContent="space-between"
            padding="S"
            background="background"
            alignItems="center"
          >
            <Label marginBottom="0" marginRight="XS">
              Tylko niezapłacone
            </Label>
            <Switch
              name="status"
              value={status === "unpaid"}
              onChange={({ name, value }) => {
                const newStatus = value ? "unpaid" : "all"
                setStatus(newStatus)
                fetchData(0, newStatus)
              }}
            />
          </SwitchWrapper>
        </Row>
        <TransactionList
          data={data.filter(el => !!el.offer)}
          loading={loading}
          totalPages={totalPages}
          page={page}
          setPage={setPage}
        />
        <Outlet />
      </PanelLayoutContent>
    </>
  )
}

export default Transactions

const SwitchWrapper = styled(Row)`
  border-radius: ${({ theme }) => theme.variable.borderRadius};
`
