import React, { useCallback, useEffect, useState } from "react"
import Modal from "@jobintrans/base-ui.components.molecules.modal"
import { useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { Box } from "@jobintrans/base-ui.components.atoms._atoms"
import { LabelH3 } from "@jobintrans/base-ui.components.atoms.typography"
import Button from "@jobintrans/base-ui.components.atoms.button"
import Accordion from "@jobintrans/base-ui.components.molecules.accordion"
import TextCheckbox from "@jobintrans/base-ui.components.atoms.text-checkbox"
import { ProfileOfferService, ToolsService } from "@jobintrans/base-ui.services.api-service"
import SelectRow from "@jobintrans/base-ui.components.molecules.select-row"
import { Trans, useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import PricingSection from "@jobintrans/base-ui.components.sections.pricing-section"
import { PLANS_ARRAY } from "@jobintrans/base-ui.data"
import { SentryService } from "services/SentryService"
import { RootState } from "store/store"
import { AddOfferHelperText, ChangeLanguageComponent } from "components/shared/ModalHelpers"
import LOGO_TRANSFER from "assets/images/payment_method_transfer.png"
import LOGO_BLIK from "assets/images/payment_method_blik.svg"
import LOGO_CARD from "assets/images/payment_method_card.png"
import { useCustomQuery } from "hooks/useCustomQuery"
import { AnalyticsService } from "services/AnalyticsService"

const ExtendOffer = () => {
  const { t, i18n } = useTranslation(["add-offer", "common"])
  const { id = "" } = useParams()
  const navigate = useNavigate()

  const { data: transactionsNotices } = useCustomQuery<[{ title: string; content: string }]>(
    { key: ["transaction-notice", i18n.language] },
    () => ToolsService.getTransactionsNotices(i18n.language),
  )

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    reset,
    formState: { errors, isValid },
  } = useForm()
  const { profile } = useSelector((state: RootState) => state.profile)

  const defaultCountryCode =
    profile?.type === "company" ? profile?.company?.countryCode : profile?.address?.countryCode
  const isPoland = defaultCountryCode === "PL"

  const [loading, setLoading] = useState(false)

  const [params, setParams] = useState<{
    code?: string
    type?: string
    period: string
    plan: string
  }>({
    period: "1",
    type: "offer",
    plan: PLANS_ARRAY["offer"][0].slug,
  })
  const [paymentMethod, setPaymentMethod] = useState(isPoland ? "transfer" : "card")

  const checkbox1 = watch("checkbox1")
  const checkbox2 = watch("checkbox2")

  const handleChangeParams = useCallback(({ code, type, period, plan }: any) => {
    setParams({
      code,
      type,
      period,
      plan,
    })
  }, [])

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true)
      const res = await ProfileOfferService.extendOffer(id, {
        plan: params?.plan,
        period: `period_${params.period}`,
        currency: i18n.language === "pl" ? "PLN" : "EUR",
        paymentMethod: paymentMethod,
      })
      AnalyticsService.track("panel_offer_extend_success", {
        plan: params?.plan,
        period: params.period,
        currency: i18n.language === "pl" ? "PLN" : "EUR",
        paymentMethod: paymentMethod,
      })
      navigate(`/transaction/status/${res.offerId}/${res.transactionId}`)
    } catch (e) {
      window.alert("Nie udało się przedłużyć ogłoszenia! Proszę spróbować później.")
      SentryService.error("[ERROR OfferService.extendOffer]:", JSON.stringify(e))
    } finally {
      setLoading(false)
    }
  }, [paymentMethod])

  useEffect(() => {
    AnalyticsService.track("panel_offer_extend_open")
  }, [])

  return (
    <Modal
      show={true}
      icon="clock"
      title={t("extend-offer.title")}
      text={t("extend-offer.text")}
      setShow={() => navigate(-1)}
      maxWidth="700px"
      showBackground={false}
      mobileFullScreen={true}
      helperElement={
        <ChangeLanguageComponent>
          <AddOfferHelperText />
        </ChangeLanguageComponent>
      }
      bottomElement={
        <Button width="100%" loading={loading} onClick={handleSubmit(onSubmit)}>
          {t("summary.submit")}
        </Button>
      }
    >
      <>
        <PricingSection
          initialCode={undefined}
          fullSize={false}
          showDiscount={false}
          setParams={handleChangeParams}
          currency={i18n.language === "pl" ? "pl" : "eur"}
          translator={e => t(`basket.${e}`, { ns: "add-offer" })}
          defaultPlan={0}
          marginBottom="M"
        />
        <Accordion marginBottom="M" title={t("summary.payment.title")}>
          <PaymentMethodList>
            {isPoland && (
              <>
                <SelectRow
                  checked={paymentMethod === "transfer"}
                  label={<LabelH3 margin="0">{t("summary.payment.fast-transfer")}</LabelH3>}
                  value={<img height={25} src={LOGO_TRANSFER} style={{ display: "block" }} />}
                  onClick={() => setPaymentMethod("transfer")}
                />
                <SelectRow
                  checked={paymentMethod === "blik"}
                  label={<LabelH3 margin="0">{t("summary.payment.blik")}</LabelH3>}
                  value={
                    <TransferRow>
                      <img height={25} src={LOGO_BLIK} style={{ display: "block" }} />
                    </TransferRow>
                  }
                  onClick={() => setPaymentMethod("blik")}
                />
              </>
            )}
            <SelectRow
              checked={paymentMethod === "card"}
              label={<LabelH3 margin="0">{t("summary.payment.card")}</LabelH3>}
              value={<img height={25} src={LOGO_CARD} style={{ display: "block" }} />}
              onClick={() => setPaymentMethod("card")}
            />
            <SelectRow
              checked={paymentMethod === "pro_forma"}
              label={<LabelH3 margin="0">{t("summary.payment.pro-forma")}</LabelH3>}
              onClick={() => setPaymentMethod("pro_forma")}
            />
          </PaymentMethodList>
        </Accordion>
        <Box marginTop="M" marginBottom="M">
          <TextCheckbox
            name="checkbox1"
            value={checkbox1}
            error={errors.checkbox1}
            rhf={register("checkbox1", {
              required: true,
            })}
            marginBottom="S"
          >
            *
            <Trans
              i18nKey={t("legal.accept-terms", { ns: "form" })}
              components={{
                terms: <a target="_blank" rel="noreferrer" href="https://jobintrans.eu/pl/terms" />,
                privacy: (
                  <a target="_blank" rel="noreferrer" href="https://jobintrans.eu/pl/privacy" />
                ),
              }}
            />
          </TextCheckbox>
          <TextCheckbox
            name="checkbox2"
            value={checkbox2}
            error={errors.checkbox2}
            rhf={register("checkbox2", {
              required: true,
            })}
            marginBottom="S"
          >
            <div
              dangerouslySetInnerHTML={{ __html: `*${t("legal.accept-payment", { ns: "form" })}` }}
            />
          </TextCheckbox>
          {(paymentMethod === "card" || paymentMethod === "blik") &&
            transactionsNotices &&
            transactionsNotices?.map((item, index) => (
              <TransferNoticeP
                marginBottom="S"
                key={index}
                dangerouslySetInnerHTML={{ __html: `${item.title} - ${item.content}` }}
              />
            ))}
        </Box>
      </>
    </Modal>
  )
}

export default ExtendOffer

const TransferRow = styled(Box)`
  display: flex;
  grid-gap: ${({ theme }) => theme.font.S};
  align-items: center;
`

const TransferNoticeP = styled(Box)`
  font-size: ${({ theme }) => theme.font.XS};
  color: ${({ theme }) => theme.color.secondary};
  font-weight: ${({ theme }) => theme.font.light};

  a,
  span {
    color: ${({ theme }) => theme.color.primary};
  }
`

const PaymentMethodList = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.XS};
`

const InvoiceWrapper = styled(Box)`
  display: grid;
  grid-template-columns: auto max-content max-content max-content;
  grid-gap: ${({ theme }) => theme.space.M};

  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr;

    > *:first-child {
      grid-column-start: 1;
      grid-column-end: 4;
    }

    > *:nth-child(5) {
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }
`

const PersonalWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.S};

  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`

const CompanyWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.S};

  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`

const TagsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: ${({ theme }) => theme.space.M};
  grid-row-gap: ${({ theme }) => theme.space.S};
`
