import React from "react"
import { Pagination } from "@jobintrans/base-ui.components.molecules.pagination"
import { Box, Column } from "@jobintrans/base-ui.components.atoms._atoms"
import { IconBox } from "@jobintrans/base-ui.components.atoms.icon"
import { Label, P } from "@jobintrans/base-ui.components.atoms.typography"
import styled, { css } from "styled-components"
import { ApplicationType } from "@jobintrans/base-ui.types.api"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { addSpacesFromPhoneNumber } from "@jobintrans/base-ui.utils.string"
import LoaderBlock from "components/shared/LoaderBlock"
import { formatDate } from "utils/date"
import OfferApplicationRowActions from "views/Profile/Offers/OfferApplications/components/OfferApplicationRowActions"

const hideInfo = (data: string, shouldHide: boolean) => {
  if (shouldHide) {
    return data.substring(0, 4) + "..."
  }

  return data
}

const OfferApplicationList = ({
  offerId,
  loading,
  data,
  page,
  totalPages,
  setPage,
}: {
  data: ApplicationType[]
  loading: boolean
  totalPages: number
  offerId: string
  page: number
  setPage: (e: number) => void
}) => {
  const { t } = useTranslation(["panel"])
  const { t: tForm } = useTranslation(["form"])
  const { t: tData } = useTranslation(["data"])

  const columnHelper = createColumnHelper<ApplicationType>()

  const columns = [
    columnHelper.accessor(row => `${row.firstName} ${row.lastName}`, {
      id: "fullName",
      header: () => tForm("fullName"),
      cell: info => (
        <StyledPaddingTd>
          <Label margin="0">{info.getValue()}</Label>
        </StyledPaddingTd>
      ),
    }),
    columnHelper.accessor(row => row.phone, {
      id: "phone",
      header: () => tForm("phone"),
      cell: info => (
        <StyledPaddingTd shouldHide={!info.row.original.isActive}>
          <Label type="secondary" margin="0">
            {hideInfo(addSpacesFromPhoneNumber(info.getValue()), !info.row.original.isActive)}
          </Label>
        </StyledPaddingTd>
      ),
    }),
    columnHelper.accessor(row => row.email, {
      id: "email",
      header: () => tForm("email"),
      cell: info => (
        <StyledPaddingTd shouldHide={!info.row.original.isActive}>
          <Label type="secondary" margin="0">
            {hideInfo(info.getValue(), !info.row.original.isActive)}
          </Label>
        </StyledPaddingTd>
      ),
    }),
    columnHelper.accessor(row => row.citizenship, {
      id: "citizenship",
      header: () => tForm("citizenship"),
      cell: info => (
        <StyledPaddingTd>
          <Label
            type="secondary"
            margin="0"
            icon={info.getValue() ? `flag-${info.getValue()}` : undefined}
          >
            {!!info.getValue() && tData(`citizenship.${info.getValue()}`)}
          </Label>
        </StyledPaddingTd>
      ),
    }),
    columnHelper.accessor(row => row.dtCreated, {
      id: "dtCreated",
      header: () => tForm("dtCreated"),
      cell: info => (
        <StyledPaddingTd>
          <Label type="secondary" margin="0">
            {formatDate(info.getValue())}
          </Label>
        </StyledPaddingTd>
      ),
    }),
    columnHelper.accessor("id", {
      header: () => undefined,
      cell: info => (
        <OfferApplicationRowActions offerId={offerId} application={info.row.original} />
      ),
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      {loading ? (
        <Content>
          <LoaderBlock height={67} />
          <LoaderBlock height={67} />
          <LoaderBlock height={67} />
        </Content>
      ) : data.length > 0 ? (
        <>
          <Content>
            <StyledTable>
              <thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th key={header.id}>
                        <Label type="secondary">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </Label>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map(row => (
                  <StyledTr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                      <StyledTd key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </StyledTd>
                    ))}
                  </StyledTr>
                ))}
              </tbody>
            </StyledTable>
          </Content>
          {data.length > 0 && (
            <Pagination
              defaultPage={page + 1}
              totalPages={totalPages}
              onChange={e => setPage(e - 1)}
            />
          )}
        </>
      ) : (
        <Column alignItems="center">
          <IconBox icon="clock" marginBottom="S" />
          <P textAlign="center" small>
            {t("offers.offer.candidates.empty")}
          </P>
        </Column>
      )}
    </>
  )
}

export default OfferApplicationList

const Content = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.space.XS};
`

const StyledTable = styled.table`
  border-spacing: 0 ${({ theme }) => theme.space.XS};
`

const StyledTd = styled.td`
  padding: ${({ theme }) => theme.space.XXS};
  min-height: 55px;
`

const StyledPaddingTd = styled.div<{
  shouldHide?: boolean
}>`
  padding: ${({ theme }) => theme.space.XXS};

  ${({ shouldHide }) =>
    shouldHide &&
    css`
      filter: blur(5px);
    `};
`

const StyledTr = styled.tr`
  box-shadow: 0 0 0 1px ${({ theme }) => theme.color.border};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
`
